import React from 'react';

import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { OOI_EXPERIMENTS } from '@app/experiments';
import EmptyStates from '@app/external/common/components/empty-states';
import { FullHeightLayout, SimpleLayout } from '@app/external/common/components/layout';
import Page from '@app/external/common/components/page';
import forDevice from '@app/external/common/hoc/for-device';
import styles from './no-post-found.scss';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

const NoPostFound: React.FC = () => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
    return (
      <article data-hook="empty-state-container" className={styles.root}>
        <h2 data-hook="empty-states__title">{t('no-post-found.title')}</h2>
        <p>{t('no-post-found.message')}</p>
      </article>
    );
  }

  return (
    <Page noSpacing>
      <Layout>
        <EmptyStates
          title={t('no-post-found.title')}
          content={t('no-post-found.message')}
          className={styles.container}
          titleClassName={styles.title}
        />
      </Layout>
    </Page>
  );
};

export default NoPostFound;
