import { isSameDay } from '../../helpers/date';
import type { NormalizedPost } from '../../types';

export const isPostUpdatedAfterFirstPublish = (post: NormalizedPost) => {
  return Boolean(
    post.firstPublishedDate &&
      post.lastPublishedDate &&
      !isSameDay(post.firstPublishedDate, post.lastPublishedDate),
  );
};
