import React from 'react';
import classNames from 'classnames';
import { TagLayout } from '@wix/communities-blog-client-common';
import { useSelector } from '@app/external/common/components/runtime-context';
import { getTagLayout } from '@app/external/common/selectors/app-settings-selectors';
import styles from './tags-empty-state.scss';

export const TagsEmptyState: React.FC<{ emptyState: string }> = ({ emptyState }) => {
  const tagLayout = useSelector(getTagLayout);
  const layoutClass = tagLayout === TagLayout.Button ? styles.buttonLayout : styles.textLayout;

  return <div className={classNames(styles.container, layoutClass)}>{emptyState}</div>;
};

export default TagsEmptyState;
