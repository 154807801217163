import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ButtonTooltip from '../../../common/components/button-tooltip';
import { getSocialSharingIcon } from '../../../common/services/social-sharing-icons';
import styles from './post-share-button.scss';

const SHOW_TIMEOUT = 200;
const HIDE_TIMEOUT = 3000;

class PostShareButton extends Component {
  state = {
    isTooltipVisible: false,
  };

  buttonRef = createRef();

  componentWillUnmount() {
    this.hideTooltip();
  }

  showTooltip() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ isTooltipVisible: true }, () => {
        this.timeout = setTimeout(() => this.hideTooltip(), HIDE_TIMEOUT);
      });
    }, SHOW_TIMEOUT);
  }

  hideTooltip() {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  }

  handleMouseEnter = () => {
    this.showTooltip();
  };

  handleMouseLeave = () => {
    this.hideTooltip();
  };

  handleClick = () => {
    const { onClick, type } = this.props;
    onClick(type);
  };

  renderTooltip = () => {
    if (!this.props.tooltipText) {
      return null;
    }

    return (
      <ButtonTooltip target={this.buttonRef.current} isVisible={this.state.isTooltipVisible}>
        {this.props.tooltipText}
      </ButtonTooltip>
    );
  };

  render = () => {
    const { type, title } = this.props;
    const iconClassName = classNames(styles.icon, 'blog-icon-fill', {
      'blog-button-hover-fill': type === 'link',
    });
    const Icon = getSocialSharingIcon(type);

    return (
      <button
        ref={this.buttonRef}
        className={classNames(styles.button, styles[type])}
        onClick={this.handleClick}
        aria-label={title}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-hook={`share-button__${type}`}
      >
        <Icon className={iconClassName} />
        <span className={styles.clickableArea} />
        {this.renderTooltip()}
      </button>
    );
  };
}

PostShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
};

export default PostShareButton;
