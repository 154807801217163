import React from 'react';
import classNames from 'classnames';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { getIsAppBundle, getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '@app/experiments';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import { HeaderDesktop } from '../header';
import HeaderNavigation from '../header-navigation';
import HeaderWidget from '../header-widget';
import { useSelector } from '../runtime-context';
import SearchInput from '../search-input';
import styles from './category-desktop-header.scss';

type Props = {
  postSlug?: string;
};

const CategoryDesktopHeader: React.FC<Props> = ({ postSlug }) => {
  const { experiments } = useExperiments();
  const isPostPageBundle = useSelector(getIsPostPageBundle);

  if (isPostPageBundle && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
    return <CategoryDesktopHeaderPost postSlug={postSlug} />;
  }

  return <CategoryDesktopHeaderFeed postSlug={postSlug} />;
};

/** Used in Post page */
const CategoryDesktopHeaderPost: React.FC<Props> = ({ postSlug }) => {
  const post = useSelector((state) => (postSlug ? getPostBySlug(state, postSlug) : null));

  return (
    <HeaderDesktop
      className={classNames(styles.container, styles.inPostPage, styles.menuDisplay)}
      left={<HeaderNavigation className={styles.menuCategoriesDisplay} />}
      right={
        <HeaderWidget
          searchInput={
            <SearchInput
              className={styles.menuSearchDisplay}
              post={post}
              inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
              borderClassName="blog-desktop-header-search-border-background-color"
              iconClassName="blog-desktop-header-search-icon-fill"
            />
          }
        />
      }
    />
  );
};

/** Used in Feed (& legacy post) page */
const CategoryDesktopHeaderFeed: React.FC<Props> = ({ postSlug }) => {
  const { showSearch, showCategoryLabels } = useBlogMenuSettings();
  const isAppBundle = useSelector(getIsAppBundle);
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const post = useSelector((state) => (postSlug ? getPostBySlug(state, postSlug) : null));
  const isResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const menuSettings = useBlogMenuSettings();

  const shouldShowHeader = isResponsiveEditor
    ? menuSettings.showCategoryLabels
    : menuSettings.showBlogMenu;

  if (shouldShowHeader === false) {
    return null;
  }

  return (
    <HeaderDesktop
      className={classNames(
        styles.container,
        isAppBundle && styles.noMargin,
        isPostPageBundle && styles.inPostPage,
      )}
      left={showCategoryLabels ? <HeaderNavigation /> : <div />}
      right={
        <HeaderWidget
          searchInput={
            showSearch ? (
              <SearchInput
                post={post}
                inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
                borderClassName="blog-desktop-header-search-border-background-color"
                iconClassName="blog-desktop-header-search-icon-fill"
              />
            ) : null
          }
        />
      }
    />
  );
};

export default CategoryDesktopHeader;
