import React from 'react';
import classnames from 'classnames';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { PaywallBanner } from '@wix/communities-blog-client-common/dist/esm/components';
import { useSelector } from '@app/external/common/components/runtime-context';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import { getAppSettings } from '@app/external/common/selectors/app-settings-base-selectors';
import { getSection } from '@app/external/common/selectors/section-selectors';
import type { NormalizedPost } from '@app/external/common/types';
import { usePostPaywallContent } from '@app/external/post-page/containers/post-page/post-page-css-per-bp/use-post-paywall-content';
import styles from './post-content.scss';

type PaywallBannerWithLinkProps = {
  paywallContent: NormalizedPost['paywallRichContent'];
};

const RichContentViewer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "rich-content-viewer" */ '../../../rich-content/components/rich-content-viewer'
    ),
  {
    fallback: null,
  },
);
type PostContentProps = {
  post: NormalizedPost;
};

export const PostContent: React.FC<PostContentProps> = ({ post }) => {
  const { contentFontClassName } = useFontClassName();
  const paywallContent = usePostPaywallContent(post);

  const postContentBodyClass = 'post-content__body';

  return (
    <article className={contentFontClassName}>
      <div
        className={classnames(postContentBodyClass, styles.rceNextView)}
        suppressHydrationWarning
      >
        <RichContentViewer
          initialRawState={post.content}
          post={post}
          parentClass={postContentBodyClass}
        />
      </div>
      {post?.canSeePaidContent === false && paywallContent && (
        <div className={styles.paywallContainer}>
          <PaywallBannerWithLink paywallContent={paywallContent} />
        </div>
      )}
    </article>
  );
};

const PaywallBannerWithLink: React.FC<PaywallBannerWithLinkProps> = ({ paywallContent }) => {
  const appSettings = useSelector(getAppSettings);
  const section = useSelector(getSection);

  return (
    <PaywallBanner
      banner={paywallContent}
      viewer={RichContentViewer}
      appSettings={appSettings}
      section={section}
      className={styles.banner}
    />
  );
};

export default PostContent;
