import { useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  AUTHOR_INFO_TYPE_NAME,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
  RelatedPostsLabel,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
  getIsTagsEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsSeeAllPostsLinkEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { getMainCategory } from '../store/categories/categories-selectors';
import { type NormalizedPost } from '../types';
import { type UseFeedMetadataSettings } from './use-feed-metadata-settings';

export type UsePostPageSettings = UseFeedMetadataSettings & {
  showRelatedPostsSeeAllLink: boolean;
};

type UsePostPageSettingsParams = {
  post: NormalizedPost;
};

export const usePostPageSettings = (params: UsePostPageSettingsParams): UsePostPageSettings => {
  const styles = useStyles();
  const { experiments } = useExperiments();
  const isPostInPreview = useSelector(getIsPostInPreview);
  const isTpaSettings = useSelector(hasTPACommentsSettings);
  const tpaSettingsEnabled = useSelector(getTPASettingsIsCommentsEnabled);
  const commentsEnabled = useSelector(getIsCommentsEnabled);

  const isCommentsEnabled =
    !isPostInPreview && isTpaSettings ? tpaSettingsEnabled : commentsEnabled;

  const isRelatedPostsEnabled = useSelector(
    (state) => !isPostInPreview && getIsRelatedPostsEnabled(state),
  );
  const showRelatedPostsSeeAllLink = useSelector(getIsSeeAllPostsLinkEnabled) !== false;
  const relatedPostsLabelKey = useSelector((state) =>
    !isPostInPreview ? getRelatedPostsLabel(state) : undefined,
  );
  const showViewCount = useSelector((state) => !isPostInPreview && getIsViewCountEnabled(state));
  const showCommentCount = useSelector(
    (state) => isCommentsEnabled && getIsCommentCountEnabled(state),
  );
  const showLikeCount = useSelector((state) => !isPostInPreview && getIsLikeCountEnabled(state));
  const showAuthorName = useSelector(getIsAuthorNameEnabled);
  const authorInfoType = useSelector(getAuthorInfoType);
  const showPostUpdatedDate = useSelector(getIsPostUpdatedDateEnabled);
  const showCategoryLabels = useSelector(getIsCategoryLabelsEnabled);
  const showPostTitle = useSelector(getIsPostTitleEnabled);
  const showPublishDate = useSelector(getIsPostPublishDateEnabled);
  const showReadingTime = useSelector(getIsReadingTimeEnabled);
  const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
  const showAuthorPicture = useSelector((state) =>
    getIsAuthorPictureEnabled(state, oldShowAuthorPictureValue),
  );
  const showCategoryLabel = useSelector((state) =>
    getMainCategory(state, params.post) ? getIsCategoryLabelEnabled(state) : false,
  );
  const showPostRating = useSelector(getIsPostRatingEnabled);
  const showTags = useSelector(getIsTagsEnabled);
  const showMoreOptionsMenu = useSelector(getIsMoreOptionsMenuEnabled);

  if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
    return {
      showComments: isCommentsEnabled,
      showViewCount: styles.get(stylesParams.showViewCount),
      showCommentCount: styles.get(stylesParams.showCommentCount),
      showLikeCount: styles.get(stylesParams.showLikeCount),
      showAuthorName: styles.get(stylesParams.showAuthorName),
      showAuthorPicture: styles.get(stylesParams.showAuthorPicture),
      showPostUpdatedDate: styles.get(stylesParams.showPostUpdatedDate),
      showCategoryLabel: false, // Unused
      showPostDescription: true, // Unused
      showCategoryLabels: styles.get(stylesParams.showCategoryLabel),
      showPostTitle: styles.get(stylesParams.showPostTitle),
      showPublishDate: styles.get(stylesParams.showPostPublishDate),
      showReadingTime: styles.get(stylesParams.showReadingTime),
      showTags: styles.get(stylesParams.showTags),
      showMoreOptionsMenu: styles.get(stylesParams.showMoreOptionsMenu),
      isMetadataFooterVisible:
        styles.get(stylesParams.showViewCount) ||
        styles.get(stylesParams.showCommentCount) ||
        styles.get(stylesParams.showLikeCount),
      isMetadataHeaderVisible:
        styles.get(stylesParams.showAuthorName) ||
        styles.get(stylesParams.showPostPublishDate) ||
        styles.get(stylesParams.showReadingTime),
      showRelatedPosts: styles.get(stylesParams.showRelatedPosts),
      showRelatedPostsSeeAllLink: styles.get(stylesParams.showRelatedPostsSeeAllLink),
      relatedPostsLabelKey: styles.get(stylesParams.showRelatedPostsLabel)
        ? styles.get(stylesParams.showRelatedPostsLabelType) === RelatedPostsLabel.Recent
          ? 'recent-posts.title'
          : 'related-posts.title'
        : undefined,
      showPostRating: styles.get(stylesParams.showPostRating),
    } satisfies UsePostPageSettings;
  }

  return {
    isMetadataFooterVisible:
      !isPostInPreview && (showViewCount || showCommentCount || showLikeCount),
    isMetadataHeaderVisible: showAuthorName || showPublishDate || showReadingTime,
    showAuthorName,
    showAuthorPicture,
    showCategoryLabel,
    showCategoryLabels,
    showPostDescription: true,
    showCommentCount,
    showComments: isCommentsEnabled,
    showLikeCount,
    showPostRating,
    showPostTitle,
    showPostUpdatedDate,
    showPublishDate,
    showReadingTime,
    showViewCount,
    showTags,
    showMoreOptionsMenu,

    showRelatedPosts: isRelatedPostsEnabled,
    relatedPostsLabelKey,
    showRelatedPostsSeeAllLink,
  } satisfies UsePostPageSettings;
};
