import React from 'react';
import classNames from 'classnames';
import {
  SECTION_BLOG_PAGE,
  TagLayout,
  TAG_CLOUD_MOBILE_PARAMS,
} from '@wix/communities-blog-client-common';
import LinkText from '@app/external/common/components/link-text';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import {
  getTagHorizontalSpacing,
  getTagLayout,
  getTagVerticalSpacing,
  getUseTagMobileSettings,
} from '@app/external/common/selectors/app-settings-selectors';
import { getTagUrl } from '@app/external/common/services/get-tag-url';
import { isSite as getIsSite } from '@app/external/common/store/basic-params/basic-params-selectors';
import { getSectionUrl } from '@app/external/common/store/topology/topology-selectors';
import type { AppState } from '@app/external/common/types';
import styles from './tag.scss';

type Props = {
  slug: string;
  label: string;
  id: string;
  postId?: string;
  postCount?: string;
  a11yText: string;
  isDemo?: boolean;
};

const Tag = ({ label, id, postId, postCount, slug, a11yText, isDemo }: Props) => {
  const actions = useActions();
  const {
    tagLayout,
    tagVerticalSpacing,
    tagHorizontalSpacing,
    tagUrl,
    isSite,
    useMobileDesignSettings,
    useMobileLayoutSettings,
  } = useSlice(slug);

  const tagStyles = {
    marginLeft: tagHorizontalSpacing,
    marginTop: tagVerticalSpacing,
  };

  const textTagClasses = [
    styles.blogPostTextTagsFont,
    styles.blogPostTextTagsColor,
    ...(useMobileDesignSettings ? [styles.blogPostTextTagsMobileDesign] : []),
  ];
  const buttonTagClasses = [
    styles.tagButton,
    styles.blogPostButtonTagsFont,
    styles.blogPostButtonTagsColor,
    styles.blogPostButtonTagsBorder,
    styles.blogPostButtonTagsBackground,
    styles.blogPostButtonTagsBorderColor,
    styles.blogPostButtonTagsPadding,
    ...(useMobileDesignSettings ? [styles.blogPostButtonTagsMobileDesign] : []),
    ...(useMobileLayoutSettings ? [styles.blogPostButtonTagsMobileLayout] : []),
  ];

  const tagClassName = classNames(
    styles.tag,
    styles.tagLink,
    ...(tagLayout === TagLayout.Button ? buttonTagClasses : textTagClasses),
  );

  const clickHandler = (event: React.MouseEvent) => {
    if (!isSite) {
      event.preventDefault();
      return actions.navigateInPreview(tagUrl, SECTION_BLOG_PAGE);
    }

    actions.tagClicked({ label, id, postId, isDemo });
  };
  return (
    <li className={styles.tag} style={tagStyles}>
      <a onClick={clickHandler} href={tagUrl} className={tagClassName}>
        <LinkText postCount={postCount} a11yText={a11yText}>
          {label}
        </LinkText>
      </a>
    </li>
  );
};

const useSlice = (slug: string) => {
  const tagLayout = useSelector(getTagLayout);
  const tagVerticalSpacing = useSelector(getTagVerticalSpacing);
  const tagHorizontalSpacing = useSelector(getTagHorizontalSpacing);
  const sectionUrl = useSelector(getSectionUrl);
  const tagUrl = getTagUrl(sectionUrl, slug);
  const isSite = useSelector(getIsSite);
  const useMobileDesignSettings = useSelector((state: AppState) =>
    getUseTagMobileSettings(
      state,
      TAG_CLOUD_MOBILE_PARAMS.isMobileDesignSettingsEnabled.appSettingsPath,
    ),
  );
  const useMobileLayoutSettings = useSelector((state: AppState) =>
    getUseTagMobileSettings(
      state,
      TAG_CLOUD_MOBILE_PARAMS.isMobileLayoutSettingsEnabled.appSettingsPath,
    ),
  );

  return {
    tagLayout,
    tagVerticalSpacing,
    tagHorizontalSpacing,
    tagUrl,
    isSite,
    useMobileDesignSettings,
    useMobileLayoutSettings,
  };
};

export default Tag;
