import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import Tags from './tags';

type Props = {
  postId?: string;
  tags?: BlogTag[];
  isPostPageTags: boolean;
  shouldSortTags: boolean;
};

const TagsWithTranslation = (props: Props) => {
  const { t } = useTranslation();

  if (props.tags === undefined) {
    return null;
  }

  const translations = {
    tagsLabel: t('post-page.tags-label'),
    emptyState: t('empty-state.no-tags'),
    tags: props.tags.reduce((acc, tag) => {
      const tagId = tag.id;

      if (tagId) {
        Object.assign(acc, {
          [tagId]: {
            a11yText: t('tag-link.a11y-post-count', {
              count: tag.publishedPostCount,
            }),
            postCount: t('tag-link.post-count', {
              count: tag.publishedPostCount,
            }),
          },
        });
      }

      return acc;
    }, {} as { [tagId: string]: { a11yText: string; postCount: string } }),
  };

  return <Tags tags={props.tags} postId={props.postId} {...props} translations={translations} />;
};

export default TagsWithTranslation;
