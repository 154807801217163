import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import Slider from 'react-slick';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import useCardBorderWidth from '@app/external/common/hooks/use-card-border-width';
import { useFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import type { NormalizedPost } from '../../../common/types';
import RecentPostsListItem from '../recent-posts-list-item';
import { Dot } from './dot';
import styles from './recent-post-carousel.scss';

interface RecentPostCarouselProps {
  posts?: NormalizedPost[];
}

/** @deprecated Remove when merging OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL */
const RecentPostCarousel = ({ posts = [] }: RecentPostCarouselProps) => {
  const { isRTL } = useEnvironment();
  const { borderWidth } = useCardBorderWidth();
  const { isMetadataFooterVisible } = useFeedMetadataSettings();

  return (
    <div dir="ltr">
      <Slider
        arrows={false}
        className={styles.container}
        dots={true}
        useCSS={true}
        infinite={true}
        customPaging={Dot}
        centerMode={true}
        centerPadding="30px"
        slidesToShow={1}
        slidesToScroll={1}
      >
        {map(posts, (post) => (
          <div className={styles.slide} key={resolveId(post)} dir={isRTL ? 'rtl' : undefined}>
            <div
              className={classNames(styles.postWrapper, 'blog-card-border-color')}
              style={{ borderWidth }}
            >
              <RecentPostsListItem post={post} isMetadataFooterVisible={isMetadataFooterVisible} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RecentPostCarousel;
