import React from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { type RoutePostParams } from '../../constants/routes';
import LazyPostPageCssPerBp from './post-page-css-per-bp';
import PostPageOld from './post-page-old';

type PostPageProps = {
  params: RoutePostParams;
};

const PostPage = (props: PostPageProps) => {
  const { experiments } = useExperiments();

  if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
    return <LazyPostPageCssPerBp {...props} />;
  }

  return <PostPageOld {...props} />;
};

export default PostPage;
