import React from 'react';
import Avatar from '@app/external/common/components/avatar';
import ProfileLink from '@app/external/common/components/link/profile-link';
import { type NormalizedPost } from '@app/external/common/types';
import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import { useSettingsContext } from '../post/settings-context';
import styles from './post-page-metadata-mobile.scss';

const PostPageMetadataMobile: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const {
    showAuthorName,
    showAuthorPicture,
    isMetadataHeaderVisible,
    showPublishDate,
    showReadingTime,
  } = useSettingsContext();

  return (
    <div className={styles.container}>
      {showAuthorName && showAuthorPicture && (
        <ProfileLink user={post.owner}>
          <Avatar user={post.owner} isNameVisible={false} />
        </ProfileLink>
      )}
      {isMetadataHeaderVisible && (
        <AuthorWithPostDetails
          post={post}
          showAuthorName={showAuthorName}
          showPublishDate={showPublishDate}
          showReadingTime={showReadingTime}
        />
      )}
    </div>
  );
};

export default PostPageMetadataMobile;
