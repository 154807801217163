import React from 'react';
import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { useSelector } from '@app/external/common/components/runtime-context';
import {
  getIsTagLabelEnabled,
  getNumberOfTags,
  getTagAlignment,
  getTagHorizontalSpacing,
  getTagOrder,
  getTagShowPostCount,
  getTagVerticalSpacing,
} from '@app/external/common/selectors/app-settings-selectors';
import { getTagTextAlignment, sortTags } from '@app/external/common/services/tag-helpers';
import Tag from './tag';
import TagsEmptyState from './tags-empty-state';
import styles from './tags.scss';

type Props = {
  postId?: string;
  tags: BlogTag[];
  translations: {
    tagsLabel: string;
    emptyState: string;
    tags: {
      [tagId: string]: {
        a11yText: string;
        postCount: string;
      };
    };
  };
  isPostPageTags?: boolean;
  shouldSortTags?: boolean;
};

export const PostTags: React.FC<Props> = ({
  postId,
  tags,
  translations,
  isPostPageTags,
  shouldSortTags = true,
}) => {
  const {
    isTagLabelEnabled,
    tagAlignment,
    tagVerticalSpacing,
    tagHorizontalSpacing,
    tagOrder,
    showPostCount,
    numberOfTags,
  } = useSlice(isPostPageTags);

  if (!tags?.length) {
    return isPostPageTags ? null : <TagsEmptyState emptyState={translations.emptyState} />;
  }

  const tagWrapperStyles = {
    marginLeft: -tagHorizontalSpacing,
    marginTop: -tagVerticalSpacing,
  };

  const sortedTags = shouldSortTags ? sortTags(tags, tagOrder) : tags;
  const preparedTags = isPostPageTags ? sortedTags : sortedTags.slice(0, numberOfTags);

  return (
    <div
      style={{ textAlign: getTagTextAlignment(tagAlignment) }}
      className={isPostPageTags ? styles.postPageTagContainer : undefined}
    >
      {isTagLabelEnabled && <p className={styles.tagLabel}>{translations.tagsLabel}</p>}
      <nav aria-label="tags" className={styles.navigation} style={tagWrapperStyles}>
        <ul>
          {preparedTags.map(({ id, slug, label }) => (
            <Tag
              postId={postId}
              key={id}
              id={id!}
              slug={slug!}
              label={label!}
              a11yText={translations.tags[id!].a11yText}
              postCount={showPostCount ? translations.tags[id!].postCount : undefined}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

const useSlice = (isPostPageTags: boolean | undefined) => {
  const isTagLabelEnabled = useSelector(getIsTagLabelEnabled);
  const tagAlignment = useSelector(getTagAlignment);
  const tagVerticalSpacing = useSelector(getTagVerticalSpacing);
  const tagHorizontalSpacing = useSelector(getTagHorizontalSpacing);
  const tagOrder = useSelector(getTagOrder);
  const showPostCount = useSelector((state) => getTagShowPostCount(state) && !isPostPageTags);
  const numberOfTags = useSelector(getNumberOfTags);

  return {
    isTagLabelEnabled,
    tagAlignment,
    tagVerticalSpacing,
    tagHorizontalSpacing,
    tagOrder,
    showPostCount,
    numberOfTags,
  };
};

export default PostTags;
