import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import PostPageMetadataDesktop from './post-page-metadata-desktop';
import PostPageMetadataMobile from './post-page-metadata-mobile';
import type { Props } from './types';

export default (props: Props) => {
  const { isMobile } = useEnvironment();
  const Component = isMobile ? PostPageMetadataMobile : PostPageMetadataDesktop;

  return <Component {...props} />;
};
