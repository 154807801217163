import loadable from '@wix/yoshi-flow-editor/loadable';
import type PostPagePost from './post-page-post';
import type PostPage from './post-page-root';

const LazyPostPage = loadable(() => import('./post-page-root')) as typeof PostPage;
export const LazyPostPagePost = loadable(
  () => import(/* webpackChunkName: "full-post-layout-v2" */ './post-page-post'),
) as typeof PostPagePost;

export default LazyPostPage;
