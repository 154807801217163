import React from 'react';
import classNames from 'classnames';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useIsFeedDesignEnabled from '@app/external/common/hooks/use-is-feed-design-enabled';
import { type NormalizedPost } from '@app/external/common/types';
import ContentExcerpt from '../../../common/components/content-excerpt';
import DotDotDot from '../../../common/components/dotdotdot';
import Link from '../../../common/components/link/internal-link';
import { PostFooter } from '../../../common/components/post-footer';
import { HorizontalSeparator } from '../../../common/components/separator';
import styles from './recent-posts-list-item.scss';

type RecentPostsListItemWithoutCoverProps = {
  post: NormalizedPost;
  isMetadataFooterVisible: boolean;
};

const RecentPostsListItemWithoutCover: React.FC<RecentPostsListItemWithoutCoverProps> = ({
  post,
  isMetadataFooterVisible,
}) => {
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const { titleFontClassName, contentFontClassName, contentFontClassNameWithStyle } =
    useFontClassName();
  const titleClassName = classNames(styles.titleWrapper, 'blog-post-title-color');
  const titleContainerClassName = classNames(
    !applyFeedDesign && titleFontClassName,
    getPostClassName('title-font'),
  );
  const containerClassName = classNames(
    styles.main,
    styles.withoutCover,
    !applyFeedDesign && contentFontClassName,
    getPostClassName('description-font'),
  );
  const contentClassName = classNames(
    !applyFeedDesign && contentFontClassNameWithStyle,
    getPostClassName('description-style-font'),
  );

  return (
    <div className={containerClassName}>
      <div className={titleContainerClassName} data-hook="recent-post__title">
        <p className={titleClassName}>
          <Link className={styles.title} to={`/${post.slug}`} fullRoute={post.link}>
            <DotDotDot clamp={3} useExactLineHeight>
              {post.title}
            </DotDotDot>
          </Link>
        </p>
      </div>
      <div className={contentClassName} data-hook="recent-post__description">
        <div className={styles.content}>
          <ContentExcerpt post={post} lineCount={4} maxLength={200} />
        </div>
      </div>
      {isMetadataFooterVisible && (
        <div className={styles.footerWrapper}>
          <HorizontalSeparator className={styles.separator} />
          <div className={styles.footer}>
            <PostFooter inPostPage post={post} displayIcons />
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentPostsListItemWithoutCover;
