import { useMemo } from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  getIsPostPageBundle,
  type SOCIAL_SHARING_PROVIDERS,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsPrintEnabled,
  getIsSocialSharingEnabled,
  getPinterestEnabled,
  getSocialSharingProviders,
  getTwitterEnabled,
} from '../selectors/app-settings-selectors';

type Provider = (typeof SOCIAL_SHARING_PROVIDERS)[number];

type PostPageShareProviderParams = {
  providers: Provider[];
  isPrintEnabled: boolean;
  isPluginPinterestEnabled: boolean;
  isPluginXEnabled: boolean;
};

export const usePostPageShareProviders = (): PostPageShareProviderParams => {
  const styles = useStyles();
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const { experiments } = useExperiments();

  const isNewPostPageSettingsUrl =
    isPostPageBundle && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL);

  /** Used in Feed - Full Post layout */
  const isRootParamEnabledLegacy = useSelector(getIsSocialSharingEnabled);
  const isRootParamEnabledNew = styles.get(stylesParams.showShare);
  const isRootParamEnabled = isNewPostPageSettingsUrl
    ? isRootParamEnabledNew
    : isRootParamEnabledLegacy;

  /** Used in Feed - Full Post layout */
  const isPluginPinterestEnabledLegacy = useSelector(getPinterestEnabled);
  const isPluginPinterestEnabledNew = styles.get(stylesParams.showSharePluginPinterest);
  const isPluginPinterestEnabled = isNewPostPageSettingsUrl
    ? isPluginPinterestEnabledNew
    : isPluginPinterestEnabledLegacy;

  /** Used in Feed - Full Post layout */
  const isPluginXEnabledLegacy = useSelector(getTwitterEnabled);
  const isPluginXEnabledNew = styles.get(stylesParams.showSharePluginX);
  const isPluginXEnabled = isNewPostPageSettingsUrl ? isPluginXEnabledNew : isPluginXEnabledLegacy;

  /** Used in Feed - Full Post layout */
  const providersLegacy: Provider[] = useSelector(getSocialSharingProviders);
  const providersNew: Provider[] = useMemo((): Provider[] => {
    const providers: Provider[] = [];

    if (styles.get(stylesParams.showShareIconFacebook)) {
      providers.push('facebook');
    }

    if (styles.get(stylesParams.showShareIconX)) {
      providers.push('twitter');
    }

    if (styles.get(stylesParams.showShareIconLinkedIn)) {
      providers.push('linked-in');
    }

    if (styles.get(stylesParams.showShareIconLink)) {
      providers.push('link');
    }

    return providers;
  }, [styles]);
  const providers = isNewPostPageSettingsUrl ? providersNew : providersLegacy;

  /** Used in Feed - Full Post layout */
  const isPrintEnabledLegacy = useSelector(getIsPrintEnabled);
  const isPrintEnabledNew = styles.get(stylesParams.showShareIconPrint);
  const isPrintEnabled = isNewPostPageSettingsUrl ? isPrintEnabledNew : isPrintEnabledLegacy;

  if (!isRootParamEnabled) {
    return {
      isPrintEnabled: false,
      isPluginPinterestEnabled: false,
      isPluginXEnabled: false,
      providers: [],
    };
  }

  return {
    providers,
    isPrintEnabled,
    isPluginPinterestEnabled,
    isPluginXEnabled,
  };
};
