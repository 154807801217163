import React from 'react';
import classNames from 'classnames';
import Avatar from '@app/external/common/components/avatar';
import ProfileLink from '@app/external/common/components/link/profile-link';
import PostMetadata from '../../../post-page/components/post-metadata';
import PostMetadataLayout from '../../../post-page/components/post-metadata-layout';
import { useSettingsContext } from '../post/settings-context';
import type { Props } from './types';
import styles from './post-page-metadata-desktop.scss';

const PostPageMetadataDesktop: React.FC<Props> = ({ post, className, linkClassName }) => {
  const { showAuthorName, showAuthorPicture, showPublishDate, showReadingTime } =
    useSettingsContext();

  return (
    <PostMetadataLayout className={classNames(styles.container, className)}>
      {showAuthorName && (
        <ProfileLink user={post.owner} className={linkClassName}>
          <Avatar user={post.owner} isImageVisible={showAuthorPicture} />
        </ProfileLink>
      )}
      {(showPublishDate || showReadingTime) && (
        <PostMetadata
          date={post.firstPublishedDate || new Date()}
          readTime={post.timeToRead}
          isShortReadTimeLabel={false}
          showPublishDate={showPublishDate}
          showReadingTime={showReadingTime}
        />
      )}
    </PostMetadataLayout>
  );
};

export default PostPageMetadataDesktop;
