import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import PostMainActionsDesktop from './post-main-actions-desktop';
import PostMainActionsMobile from './post-main-actions-mobile';
import type { Props } from './types';

export default (props: Props) => {
  const { isMobile } = useEnvironment();
  const Component = isMobile ? PostMainActionsMobile : PostMainActionsDesktop;

  return <Component {...props} />;
};
