import React from 'react';
import classNames from 'classnames';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '@app/experiments';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import HeaderButtons from '../header-buttons';
import HeaderTitle from '../header-title';
import { useSelector } from '../runtime-context';
import styles from './header-mobile.scss';

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  menu?: React.ReactNode;
};

const HeaderMobile: React.FC<Props> = ({
  left = (
    <div className={styles.left}>
      <span className={styles.title}>
        <HeaderTitle />
      </span>
    </div>
  ),
  right = <HeaderButtons />,
  menu,
}) => {
  const { experiments } = useExperiments();
  const isPostPage = useSelector(getIsPostPageBundle);

  if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL) && isPostPage) {
    return <HeaderMobilePost left={left} right={right} menu={menu} />;
  }

  return <HeaderMobileFeed left={left} right={right} menu={menu} />;
};

const HeaderMobilePost: React.FC<Props> = ({ left, right, menu }) => {
  const isCreatedWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);

  return (
    <div className={styles.rootPost}>
      {isCreatedWithResponsiveEditor ? null : (
        <div className={classNames(styles.header, 'blog-button-background-color')}>
          {left}
          {right}
        </div>
      )}
      {menu && (
        <div className={classNames(styles.menuCategoriesDisplay, 'blog-header-background-color')}>
          {menu}
        </div>
      )}
    </div>
  );
};

const HeaderMobileFeed: React.FC<Props> = ({ left, right, menu }) => {
  const { showBlogMenu, showCategoryLabels } = useBlogMenuSettings();
  const { contentFontClassName } = useFontClassName();
  const isCreatedWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);

  const showMenu = menu && showBlogMenu && showCategoryLabels;

  const containerClassName = classNames(
    styles.root,
    'blog-button-primary-text-color',
    contentFontClassName,
  );

  return (
    <div className={containerClassName}>
      {!isCreatedWithResponsiveEditor && showBlogMenu && (
        <div className={classNames(styles.header, 'blog-button-background-color')}>
          {left}
          {right}
        </div>
      )}
      {showMenu && <div className="blog-header-background-color">{menu}</div>}
    </div>
  );
};

export default HeaderMobile;
