import React from 'react';
import {
  SHARE_BUTTON_TRANSLATION_KEYS,
  SOCIAL_SHARING_PRINT,
} from '@wix/communities-blog-client-common';
import { usePostPrint } from '@app/external/common/hooks/use-post-print';
import PostShareButton from '../../../post-page/components/post-share-button';
import { useActions } from '../runtime-context';
import { useTranslation } from '@wix/yoshi-flow-editor';

type Props = {
  postId: string;
};

const PostPrintAction: React.FC<Props> = ({ postId }) => {
  const { t } = useTranslation();
  const actions = useActions();
  const { postPageSocialShareClicked } = actions;
  const print = usePostPrint();

  return (
    <PostShareButton
      type={SOCIAL_SHARING_PRINT}
      title={t(SHARE_BUTTON_TRANSLATION_KEYS[SOCIAL_SHARING_PRINT])}
      onClick={() => {
        postPageSocialShareClicked({
          postId,
          type: SOCIAL_SHARING_PRINT,
        });
        print();
      }}
    />
  );
};

export default PostPrintAction;
